import { createContext, ReactNode, useEffect, useState } from 'react';

interface UserOperationsPreferencesData {
  hiddenCollumnsOperationsTable: string[];
  handleHiddenColumnsOperationsTableChange: (
    data: HandleHiddenColumnsFromTableChangeData
  ) => void;
  operationsTablePageSize: number;
  handleOperationsTablePageSizeChange: (
    operationsTablePageSize: number
  ) => void;
  operationsTablePageIndex: number;
  handleOperationsTablePageIndexChange: (
    operationsTablePageIndex: number
  ) => void;
  methodsColumnFilter: string;
  handleMethodColumnFilterChange: (methodColumnFilter: string) => void;
}

interface UserOperationsPreferencesProviderProps {
  children: ReactNode;
}

interface HandleHiddenColumnsFromTableChangeData {
  isVisible: boolean;
  id: string;
}

export const UserOperationsPreferencesContext = createContext(
  {} as UserOperationsPreferencesData
);

export function UserOperationsPreferencesProvider({
  children,
}: UserOperationsPreferencesProviderProps) {
  const [operationsTablePageSize, setOperationsTablePageSize] = useState(10);
  const [hiddenCollumnsOperationsTable, setHiddenCollumnsOperationsTable] =
    useState<string[]>([]);
  const [operationsTablePageIndex, setOperationsTablePageIndex] = useState(0);
  const [methodsColumnFilter, setMethodsColumnFilter] = useState(undefined);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Colunas invisiveis da tabela de operações;
      const storedHiddenColumnsOperationsTable = localStorage.getItem(
        '@Laybackdashboard:HiddenColumnsOperationsTable'
      );

      if (storedHiddenColumnsOperationsTable) {
        setHiddenCollumnsOperationsTable(
          JSON.parse(storedHiddenColumnsOperationsTable)
        );
      }

      // Número de linhas da tabela de operações
      const storedOperationsTablePageSize = localStorage.getItem(
        '@Laybackdashboard:OperationsTablePageSize'
      );

      if (storedOperationsTablePageSize) {
        setOperationsTablePageSize(JSON.parse(storedOperationsTablePageSize));
      }

      // Método filtrado na tabela de operações
      const storedMethodsColumnFilter = localStorage.getItem(
        '@Laybackdashboard:MethodsColumnFilter'
      );

      if (storedMethodsColumnFilter) {
        setMethodsColumnFilter(JSON.parse(storedMethodsColumnFilter));
      }

      // Página da tabela de operações
      // const storedOperationsTablePageIndex = localStorage.getItem(
      //   '@Laybackdashboard:OperationsTablePageIndex'
      // );

      // if (storedOperationsTablePageIndex) {
      //   setOperationsTablePageIndex(JSON.parse(storedOperationsTablePageIndex));
      // }
    }
  }, []);

  const handleHiddenColumnsOperationsTableChange = ({
    isVisible,
    id,
  }: HandleHiddenColumnsFromTableChangeData) => {
    if (typeof window !== 'undefined') {
      if (isVisible) {
        const newHiddenColumnsOperationsTable = [
          ...hiddenCollumnsOperationsTable,
          id,
        ];
        setHiddenCollumnsOperationsTable(newHiddenColumnsOperationsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsOperationsTable',
          JSON.stringify(newHiddenColumnsOperationsTable)
        );
      } else {
        const newHiddenColumnsOperationsTable =
          hiddenCollumnsOperationsTable.filter((column) => {
            return column !== id;
          });
        setHiddenCollumnsOperationsTable(newHiddenColumnsOperationsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsOperationsTable',
          JSON.stringify(newHiddenColumnsOperationsTable)
        );
      }
    }
  };

  const handleOperationsTablePageSizeChange = (pageSize: number) => {
    setOperationsTablePageSize(pageSize);
    localStorage.setItem(
      '@Laybackdashboard:OperationsTablePageSize',
      JSON.stringify(pageSize)
    );
  };

  const handleOperationsTablePageIndexChange = (pageIndex: number) => {
    setOperationsTablePageIndex(pageIndex);
    localStorage.setItem(
      '@Laybackdashboard:OperationsTablePageIndex',
      JSON.stringify(pageIndex)
    );
  };

  const handleMethodColumnFilterChange = (methodColumnFilter: string) => {
    setMethodsColumnFilter(methodColumnFilter);
    localStorage.setItem(
      '@Laybackdashboard:MethodsColumnFilter',
      JSON.stringify(methodColumnFilter)
    );
  };

  return (
    <UserOperationsPreferencesContext.Provider
      value={{
        hiddenCollumnsOperationsTable,
        handleHiddenColumnsOperationsTableChange,
        operationsTablePageSize,
        handleOperationsTablePageSizeChange,
        operationsTablePageIndex,
        handleOperationsTablePageIndexChange,
        methodsColumnFilter,
        handleMethodColumnFilterChange,
      }}
    >
      {children}
    </UserOperationsPreferencesContext.Provider>
  );
}
