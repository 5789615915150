import { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import splitbee from '@splitbee/web';

import { theme } from '../styles/theme';
import { SidebarDrawerProvider } from '../contexts/SidebarDrawerContext';
import { HomePageSidebarDrawerProvider } from '../contexts/HomePageSidebarDrawerContext';
import { queryClient } from '../services/queryClient';

import 'react-datepicker/dist/react-datepicker.css';
import '../components/DatePicker/DatePicker.css';
import '../components/Table/IndeterminateCheckbox.css';
import 'simplebar/dist/simplebar.min.css';
import '../styles/ApexCharts/apexChartsStyles.css';

import { DateSelectorProvider } from '../contexts/DateSelectorContext';
import { AuthProvider } from '../contexts/AuthContext';
import { UserPreferencesProvider } from '../contexts/UserPreferencesContext';
import { ApiProvider } from '../contexts/ApiContext';
import { useEffect } from 'react';

function MyApp({ Component, pageProps }: AppProps) {
  // This initiliazes Splitbee.js
  useEffect(() => {
    splitbee.init();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider resetCSS theme={theme}>
        <ApiProvider>
          <AuthProvider>
            <UserPreferencesProvider>
              <HomePageSidebarDrawerProvider>
                <SidebarDrawerProvider>
                  <DateSelectorProvider>
                    <Component {...pageProps} />
                  </DateSelectorProvider>
                </SidebarDrawerProvider>
              </HomePageSidebarDrawerProvider>
            </UserPreferencesProvider>
          </AuthProvider>
        </ApiProvider>
      </ChakraProvider>

      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default MyApp;
