import { createContext, ReactNode } from 'react';
import { UserDashboardPreferencesProvider } from './UserDashboardPreferencesContext';
import { UserMethodsPreferencesProvider } from './UserMethodsPreferencesContext';
import { UserOperationsPreferencesProvider } from './UserOperationsPreferencesContext';
import { UserCountriesPreferencesProvider } from './UserCountriesPreferencesContext';
import { UserTeamsPreferencesProvider } from './UserTeamsPreferencesContext';
import { UserCompetitionsPreferencesProvider } from './UserCompetitionsPreferencesContext';

// interface UserPreferencesData {}

interface UserPreferencesProviderProps {
  children: ReactNode;
}

export const UserPreferencesContext = createContext({});

export function UserPreferencesProvider({
  children,
}: UserPreferencesProviderProps) {
  return (
    <UserPreferencesContext.Provider value={{}}>
      <UserDashboardPreferencesProvider>
        <UserTeamsPreferencesProvider>
          <UserMethodsPreferencesProvider>
            <UserOperationsPreferencesProvider>
              <UserCompetitionsPreferencesProvider>
                <UserCountriesPreferencesProvider>
                  {children}
                </UserCountriesPreferencesProvider>
              </UserCompetitionsPreferencesProvider>
            </UserOperationsPreferencesProvider>
          </UserMethodsPreferencesProvider>
        </UserTeamsPreferencesProvider>
      </UserDashboardPreferencesProvider>
    </UserPreferencesContext.Provider>
  );
}
