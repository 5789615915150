import { createContext, ReactNode, useEffect, useState } from 'react';

interface UserDashboardPreferencesData {
  isAccumulatedDashboard: boolean;
  ToogleIsAccumulatedDashboard: () => void;
  isByOperationDashboard: boolean;
  ToogleIsByOperationDashboard: () => void;
  isTeamsTableByTotal: boolean;
  ToogleIsTeamsTableByTotal: () => void;
  isMethodsTableByTotal: boolean;
  ToogleIsMethodsTableByTotal: () => void;
}

interface UserDashboardPreferencesProviderProps {
  children: ReactNode;
}

export const UserDashboardPreferencesContext = createContext(
  {} as UserDashboardPreferencesData
);

export function UserDashboardPreferencesProvider({
  children,
}: UserDashboardPreferencesProviderProps) {
  const [isAccumulatedDashboard, setIsAccumulatedDashboard] = useState(true);
  const [isByOperationDashboard, setIsByOperationDashboard] = useState(false);
  const [isTeamsTableByTotal, setIsTeamsTableByTotal] = useState(false);
  const [isMethodsTableByTotal, setIsMethodsTableByTotal] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Gráfico de lucro do dashboard principal, se está acumulado ou não
      const storedIsAccumulatedDashboard = localStorage.getItem(
        '@Laybackdashboard:IsAccumulatedDashboard'
      );

      if (storedIsAccumulatedDashboard) {
        if (storedIsAccumulatedDashboard === 'false') {
          setIsAccumulatedDashboard(false);
        } else {
          setIsAccumulatedDashboard(true);
        }
      }

      // Gráfico de lucro do dashboard principal, se está por operação ou não
      const storedIsByOperationDashboard = localStorage.getItem(
        '@Laybackdashboard:IsByOperationDashboard'
      );

      if (storedIsByOperationDashboard) {
        if (storedIsByOperationDashboard === 'false') {
          setIsByOperationDashboard(false);
        } else {
          setIsByOperationDashboard(true);
        }
      }

      // Se a tabela de time está em dinheiro ou ROI
      const storedIsTeamsTableByTotal = localStorage.getItem(
        '@Laybackdashboard:IsTeamsTableByTotal'
      );

      if (storedIsTeamsTableByTotal) {
        if (storedIsTeamsTableByTotal === 'false') {
          setIsTeamsTableByTotal(false);
        } else {
          setIsTeamsTableByTotal(true);
        }
      }

      // Se a tabela de métodos está em dinheiro ou ROI
      const storedIsMethodsTableByTotal = localStorage.getItem(
        '@Laybackdashboard:IsMethodsTableByTotal'
      );

      if (storedIsMethodsTableByTotal) {
        if (storedIsMethodsTableByTotal === 'false') {
          setIsMethodsTableByTotal(false);
        } else {
          setIsMethodsTableByTotal(true);
        }
      }
    }
  }, []);

  const ToogleIsAccumulatedDashboard = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsAccumulatedDashboard',
        JSON.stringify(!isAccumulatedDashboard)
      );
      setIsAccumulatedDashboard(!isAccumulatedDashboard);
    }
  };

  const ToogleIsByOperationDashboard = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsByOperationDashboard',
        JSON.stringify(!isByOperationDashboard)
      );
      setIsByOperationDashboard(!isByOperationDashboard);
    }
  };

  const ToogleIsTeamsTableByTotal = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsTeamsTableByTotal',
        JSON.stringify(!isTeamsTableByTotal)
      );
      setIsTeamsTableByTotal(!isTeamsTableByTotal);
    }
  };

  const ToogleIsMethodsTableByTotal = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsMethodsTableByTotal',
        JSON.stringify(!isMethodsTableByTotal)
      );
      setIsMethodsTableByTotal(!isMethodsTableByTotal);
    }
  };

  return (
    <UserDashboardPreferencesContext.Provider
      value={{
        isAccumulatedDashboard,
        ToogleIsAccumulatedDashboard,
        isByOperationDashboard,
        ToogleIsByOperationDashboard,
        isTeamsTableByTotal,
        ToogleIsTeamsTableByTotal,
        isMethodsTableByTotal,
        ToogleIsMethodsTableByTotal,
      }}
    >
      {children}
    </UserDashboardPreferencesContext.Provider>
  );
}
