import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface UserTeamsPreferencesData {
  isAccumulatedTeams: boolean;
  ToogleIsAccumulatedTeams: () => void;
  isByOperationTeams: boolean;
  ToogleIsByOperationTeams: () => void;
  teamsSidePreference: string;
  handleTeamsSidePreferenceChange: (teamSidePreference: string) => void;
  hiddenCollumnsTeamsTable: string[];
  handleHiddenColumnsTeamsTableChange: (
    data: HandleHiddenColumnsFromTableChangeData
  ) => void;
  teamsTablePageSize: number;
  handleTeamsTablePageSizeChange: (teamsTablePageSize: number) => void;
}

interface UserTeamsPreferencesProviderProps {
  children: ReactNode;
}

interface HandleHiddenColumnsFromTableChangeData {
  isVisible: boolean;
  id: string;
}

export const UserTeamsPreferencesContext = createContext(
  {} as UserTeamsPreferencesData
);

export function UserTeamsPreferencesProvider({
  children,
}: UserTeamsPreferencesProviderProps) {
  const [isAccumulatedTeams, setIsAccumulatedTeams] = useState(true);
  const [isByOperationTeams, setIsByOperationTeams] = useState(false);
  const [teamsSidePreference, setTeamsSidePreference] = useState('total');
  const [hiddenCollumnsTeamsTable, setHiddenCollumnsTeamsTable] = useState<
    string[]
  >([]);
  const [teamsTablePageSize, setTeamsTablePageSize] = useState(10);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Gráfico de lucro do dashboard de times, se está acumulado ou não
      const storedIsAccumulatedTeams = localStorage.getItem(
        '@Laybackdashboard:IsAccumulatedTeams'
      );

      if (storedIsAccumulatedTeams) {
        if (storedIsAccumulatedTeams === 'false') {
          setIsAccumulatedTeams(false);
        } else {
          setIsAccumulatedTeams(true);
        }
      }

      // Gráfico de lucro do dashboard de times, se está por operação ou não
      const storedIsByOperationTeams = localStorage.getItem(
        '@Laybackdashboard:IsByOperationTeams'
      );

      if (storedIsByOperationTeams) {
        if (storedIsByOperationTeams === 'false') {
          setIsByOperationTeams(false);
        } else {
          setIsByOperationTeams(true);
        }
      }

      // Preferencia do gráfico de time;
      const storedTeamsSidePreference = localStorage.getItem(
        '@Laybackdashboard:TeamsSidePreference'
      );

      if (storedTeamsSidePreference) {
        setTeamsSidePreference(storedTeamsSidePreference);
      }

      // Colunas invisiveis da tabela de times;
      const storedHiddenColumnsTeamsTable = localStorage.getItem(
        '@Laybackdashboard:HiddenColumnsTeamsTable'
      );

      if (storedHiddenColumnsTeamsTable) {
        setHiddenCollumnsTeamsTable(JSON.parse(storedHiddenColumnsTeamsTable));
      }

      // Número de linhas da tabela de times
      const storedTeamsTablePageSize = localStorage.getItem(
        '@Laybackdashboard:TeamsTablePageSize'
      );

      if (storedTeamsTablePageSize) {
        setTeamsTablePageSize(JSON.parse(storedTeamsTablePageSize));
      }
    }
  }, []);

  const ToogleIsAccumulatedTeams = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsAccumulatedTeams',
        JSON.stringify(!isAccumulatedTeams)
      );
      setIsAccumulatedTeams(!isAccumulatedTeams);
    }
  };

  const ToogleIsByOperationTeams = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsByOperationTeams',
        JSON.stringify(!isByOperationTeams)
      );
      setIsByOperationTeams(!isByOperationTeams);
    }
  };

  const handleHiddenColumnsTeamsTableChange = ({
    isVisible,
    id,
  }: HandleHiddenColumnsFromTableChangeData) => {
    if (typeof window !== 'undefined') {
      if (isVisible) {
        const newHiddenColumnsTeamsTable = [...hiddenCollumnsTeamsTable, id];
        setHiddenCollumnsTeamsTable(newHiddenColumnsTeamsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsTeamsTable',
          JSON.stringify(newHiddenColumnsTeamsTable)
        );
      } else {
        const newHiddenColumnsTeamsTable = hiddenCollumnsTeamsTable.filter(
          (column) => {
            return column !== id;
          }
        );
        setHiddenCollumnsTeamsTable(newHiddenColumnsTeamsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsTeamsTable',
          JSON.stringify(newHiddenColumnsTeamsTable)
        );
      }
    }
  };

  const handleTeamsTablePageSizeChange = (pageSize: number) => {
    setTeamsTablePageSize(pageSize);
    localStorage.setItem(
      '@Laybackdashboard:TeamsTablePageSize',
      JSON.stringify(pageSize)
    );
  };

  const handleTeamsSidePreferenceChange = useCallback(
    (teamSidePreference: string) => {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          '@Laybackdashboard:TeamsSidePreference',
          teamSidePreference
        );
        setTeamsSidePreference(teamSidePreference);
      }
    },
    []
  );

  return (
    <UserTeamsPreferencesContext.Provider
      value={{
        isAccumulatedTeams,
        ToogleIsAccumulatedTeams,
        isByOperationTeams,
        ToogleIsByOperationTeams,
        teamsSidePreference,
        handleTeamsSidePreferenceChange,
        hiddenCollumnsTeamsTable,
        handleHiddenColumnsTeamsTableChange,
        teamsTablePageSize,
        handleTeamsTablePageSizeChange,
      }}
    >
      {children}
    </UserTeamsPreferencesContext.Provider>
  );
}
