import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { setCookie, parseCookies, destroyCookie } from 'nookies';
import Router from 'next/router';
import { nextApi } from '../services/api';
import { User } from '../pages/api/users/getUser';

interface SignInCredentials {
  accessToken: string;
}

interface SignUpCredentials {
  accessToken: string;
  userId: string;
  email: string;
}

interface AuthContextData {
  signUp: ({ accessToken, userId }: SignUpCredentials) => Promise<User>;
  signIn: ({ accessToken }: SignInCredentials) => Promise<User | undefined>;
  signOut: () => void;
  user: User;
  isUserLoading: boolean;
  updateUser(user?: User): Promise<User>;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext({} as AuthContextData);

// let authChannel: BroadcastChannel;

export async function createUser({
  accessToken,
  userId,
  email,
}: SignUpCredentials): Promise<User> {
  try {
    const newUserResponse = await nextApi.post('/users/createUser', {
      userId,
      accessToken,
      email,
    });

    const success = newUserResponse.data.success;

    if (!success) {
      throw new Error(newUserResponse.data.message);
    }

    const user = newUserResponse.data.user;

    return user;
  } catch (err) {
    console.log(err);
    throw new Error(err.message);
  }
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User>({} as User);
  const [isUserLoading, setIsUserLoading] = useState(true);
  // const isAuthenticated = !!user;

  // useEffect(() => {
  //   authChannel = new BroadcastChannel('laybackdashboard');

  //   authChannel.onmessage = (message) => {
  //     switch (message.data) {
  //       case 'signOut':
  //         signOut();
  //         break;

  //       default:
  //         break;
  //     }
  //   };
  // }, []);

  const signOut = useCallback(() => {
    destroyCookie(undefined, 'laybackdashboard.accessToken');

    // if (process.browser && authChannel) {
    //   authChannel.postMessage('signOut');
    // }

    setUser({} as User);

    Router.push('/login');
  }, []);

  useEffect(() => {
    const { 'laybackdashboard.accessToken': accessToken } = parseCookies();

    if (accessToken) {
      nextApi
        .get<{ user: User }>('/users/getUser')
        .then((response) => {
          const { user } = response.data;

          setUser(user);
          setIsUserLoading(false);
        })
        .catch(() => {
          signOut();
        });
    }
  }, [signOut]);

  const signUp = useCallback(
    async ({ accessToken, userId, email }: SignUpCredentials) => {
      try {
        const user = await createUser({ accessToken, userId, email });

        setUser(user);
        setIsUserLoading(false);

        return user;
      } catch (error) {
        throw new Error(error.message);
      }
    },
    []
  );

  const signIn = useCallback(async ({ accessToken }: SignInCredentials) => {
    setCookie(undefined, 'laybackdashboard.accessToken', accessToken, {
      maxAge: 60 * 60 * 24, // 1 day
      path: '/',
    });

    const response = await nextApi.get<{ user: User }>('/users/getUser');

    const { user } = response.data;

    if (!user) {
      // Se não consegui pegar um user com esse userId
      // vou criar um user
      return undefined;
    }

    setUser(user);

    setIsUserLoading(false);

    return user;
  }, []);

  const updateUser = useCallback(async (inputedUser: User = undefined) => {
    let user: User;

    if (inputedUser) {
      setUser(inputedUser);

      user = inputedUser;
    } else {
      setIsUserLoading(true);

      const response = await nextApi.get<{ user: User }>('/users/getUser');

      user = response.data.user;

      if (!user) {
        return;
      }

      setUser(user);

      setIsUserLoading(false);
    }

    return user;
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signUp,
        signIn,
        signOut,
        isUserLoading,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within and AuthProvider');
  }

  return context;
}
