import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/hooks';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useEffect } from 'react';

interface HomePageSidebarDrawerProviderProps {
  children: ReactNode;
}

type HomePageSidebarDrawerContextData = UseDisclosureReturn;

const HomePageSidebarDrawerContext = createContext(
  {} as HomePageSidebarDrawerContextData
);

export function HomePageSidebarDrawerProvider({
  children,
}: HomePageSidebarDrawerProviderProps) {
  const disclosure = useDisclosure();

  const router = useRouter();

  useEffect(() => {
    disclosure.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  return (
    <HomePageSidebarDrawerContext.Provider value={disclosure}>
      {children}
    </HomePageSidebarDrawerContext.Provider>
  );
}

export const useHomePageSidebarDrawer = () =>
  useContext(HomePageSidebarDrawerContext);
