import { createContext, ReactNode, useContext, useState } from 'react';

import Cookies from 'js-cookie';
import { eachDayOfInterval, isFuture, isSameDay } from 'date-fns';

interface RangeDates {
  startDate: Date;
  endDate: Date;
}

interface DateSelectorContextData {
  startDate: Date;
  endDate: Date;
  today: Date;
  updateDates(dates: RangeDates): void;
  getDaysQuantityOfInterval(): number;
  dailyDay: Date;
  updateDailyDay(day: Date): void;
  previousDay(): void;
  nextDay(): void;
}

interface DateSelectorProviderProps {
  children: ReactNode;
}

const DateSelectorContext = createContext<DateSelectorContextData>(
  {} as DateSelectorContextData
);
const WEEK_IN_DAYS = 7;
const DAY_IN_HOUR = 24;
const HOUR_IN_MINUTES = 60;
const MINUTE_IN_SECONDS = 60;
const SECOND_IN_MS = 1000;

const WEEK_IN_MS =
  WEEK_IN_DAYS *
  DAY_IN_HOUR *
  HOUR_IN_MINUTES *
  MINUTE_IN_SECONDS *
  SECOND_IN_MS;

export function DateSelectorProvider({ children }: DateSelectorProviderProps) {
  const [startDate, setStartDate] = useState<Date>(() => {
    const startDate = Cookies.get('startDate');

    if (startDate) {
      return new Date(startDate);
    }
    const defaultStartDate = new Date(
      new Date(Date.now() - WEEK_IN_MS).setHours(0, 0, 0, 0)
    );

    Cookies.set('startDate', String(defaultStartDate));
    return defaultStartDate;
  });

  const [endDate, setEndDate] = useState<Date>(() => {
    const isEndDateToday = Cookies.get('isEndDateToday') === 'true';
    const defaultEndDate = new Date(new Date().setHours(23, 59, 59, 59));

    if (isEndDateToday) {
      Cookies.set('endDate', String(defaultEndDate));
      return defaultEndDate;
    } else {
      const endDate = Cookies.get('endDate');

      if (endDate) {
        return new Date(endDate);
      }

      Cookies.set('endDate', String(defaultEndDate));
      return defaultEndDate;
    }
  });

  const [today, setToday] = useState<Date>(() => {
    const defaultToday = new Date(new Date().setHours(0, 0, 0, 0));
    Cookies.set('today', String(defaultToday));
    return defaultToday;
  });

  const [dailyDay, setDailyDay] = useState<Date>(() => {
    const isDailyDayToday = Cookies.get('isDaylyDayToday') === 'true';
    const defaultDailyDay = new Date(new Date().setHours(0, 0, 0, 0));

    if (isDailyDayToday) {
      Cookies.set('dailyDay', String(defaultDailyDay));
      return defaultDailyDay;
    } else {
      const dailyDay = Cookies.get('dailyDay');

      if (dailyDay) {
        return new Date(dailyDay);
      }

      Cookies.set('dailyDay', String(defaultDailyDay));
      return defaultDailyDay;
    }
  });

  function updateDates({ startDate, endDate }: RangeDates) {
    const today = new Date(new Date().setHours(0, 0, 0, 0));

    const newStartDate = new Date(startDate.setHours(0, 0, 0, 0));
    const newEndDate = new Date(endDate.setHours(23, 59, 59, 59));

    Cookies.set('isEndDateToday', String(isSameDay(today, newEndDate)));

    Cookies.set('startDate', String(newStartDate));
    Cookies.set('endDate', String(newEndDate));
    Cookies.set('today', String(today));

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setToday(today);
  }

  function getDaysQuantityOfInterval() {
    const daysArray = eachDayOfInterval({ start: startDate, end: endDate });

    return daysArray.length;
  }

  function updateDailyDay(newDailyDay: Date) {
    const today = new Date(new Date().setHours(0, 0, 0, 0));

    Cookies.set('isDaylyDayToday', String(isSameDay(today, newDailyDay)));
    Cookies.set('dailyDay', String(newDailyDay));

    setDailyDay(newDailyDay);
  }

  function previousDay() {
    const newDailyDay = new Date(dailyDay.setDate(dailyDay.getDate() - 1));
    Cookies.set('dailyDay', String(newDailyDay));
    updateDailyDay(newDailyDay);
  }

  function nextDay() {
    const newDailyDay = new Date(dailyDay.setDate(dailyDay.getDate() + 1));
    if (isFuture(newDailyDay)) {
      return;
    } else {
      Cookies.set('dailyDay', String(newDailyDay));
      updateDailyDay(newDailyDay);
    }
  }

  return (
    <DateSelectorContext.Provider
      value={{
        startDate,
        endDate,
        today,
        updateDates,
        getDaysQuantityOfInterval,
        dailyDay,
        updateDailyDay,
        previousDay,
        nextDay,
      }}
    >
      {children}
    </DateSelectorContext.Provider>
  );
}

export const useDateSelector = () => useContext(DateSelectorContext);
