import { createContext, ReactNode, useEffect, useState } from 'react';

interface UserMethodsPreferencesData {
  isAccumulatedMethods: boolean;
  ToogleIsAccumulatedMethods: () => void;
  isByOperationMethods: boolean;
  ToogleIsByOperationMethods: () => void;
  hiddenCollumnsMethodsTable: string[];
  handleHiddenColumnsMethodsTableChange: (
    data: HandleHiddenColumnsFromTableChangeData
  ) => void;
  methodsTablePageSize: number;
  handleMethodsTablePageSizeChange: (methodsTablePageSize: number) => void;
  hiddenCollumnsUserMethodsTable: string[];
  handleHiddenColumnsUserMethodsTableChange: (
    data: HandleHiddenColumnsFromTableChangeData
  ) => void;
  userMethodsTablePageSize: number;
  handleUserMethodsTablePageSizeChange: (methodsTablePageSize: number) => void;
}

interface UserMethodsPreferencesProviderProps {
  children: ReactNode;
}

interface HandleHiddenColumnsFromTableChangeData {
  isVisible: boolean;
  id: string;
}

export const UserMethodsPreferencesContext = createContext(
  {} as UserMethodsPreferencesData
);

export function UserMethodsPreferencesProvider({
  children,
}: UserMethodsPreferencesProviderProps) {
  const [hiddenCollumnsMethodsTable, setHiddenCollumnsMethodsTable] = useState<
    string[]
  >([]);
  const [methodsTablePageSize, setMethodsTablePageSize] = useState(10);
  const [hiddenCollumnsUserMethodsTable, setHiddenCollumnsUserMethodsTable] =
    useState<string[]>([]);
  const [userMethodsTablePageSize, setUserMethodsTablePageSize] = useState(10);
  const [isAccumulatedMethods, setIsAccumulatedMethods] = useState(true);
  const [isByOperationMethods, setIsByOperationMethods] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Gráfico de lucro do dashboard de métodos, se está acumulado ou não
      const storedIsAccumulatedMethods = localStorage.getItem(
        '@Laybackdashboard:IsAccumulatedMethods'
      );

      if (storedIsAccumulatedMethods) {
        if (storedIsAccumulatedMethods === 'false') {
          setIsAccumulatedMethods(false);
        } else {
          setIsAccumulatedMethods(true);
        }
      }

      // Gráfico de lucro do dashboard de métodos, se está por operação ou não
      const storedIsByOperationMethods = localStorage.getItem(
        '@Laybackdashboard:IsByOperationMethods'
      );

      if (storedIsByOperationMethods) {
        if (storedIsByOperationMethods === 'false') {
          setIsByOperationMethods(false);
        } else {
          setIsByOperationMethods(true);
        }
      }

      // Colunas invisiveis da tabela de métodos;
      const storedHiddenColumnsMethodsTable = localStorage.getItem(
        '@Laybackdashboard:HiddenColumnsMethodsTable'
      );

      if (storedHiddenColumnsMethodsTable) {
        setHiddenCollumnsMethodsTable(
          JSON.parse(storedHiddenColumnsMethodsTable)
        );
      }

      // Colunas invisiveis da tabela de métodos do usuário;
      const storedHiddenColumnsUserMethodsTable = localStorage.getItem(
        '@Laybackdashboard:HiddenColumnsUserMethodsTable'
      );

      if (storedHiddenColumnsUserMethodsTable) {
        setHiddenCollumnsUserMethodsTable(
          JSON.parse(storedHiddenColumnsUserMethodsTable)
        );
      }

      // Número de linhas da tabela de métodos
      const storedMethodsTablePageSize = localStorage.getItem(
        '@Laybackdashboard:MethodsTablePageSize'
      );

      if (storedMethodsTablePageSize) {
        setMethodsTablePageSize(JSON.parse(storedMethodsTablePageSize));
      }

      // Número de linhas da tabela de métodos do usuário
      const storedUserMethodsTablePageSize = localStorage.getItem(
        '@Laybackdashboard:UserMethodsTablePageSize'
      );

      if (storedUserMethodsTablePageSize) {
        setUserMethodsTablePageSize(JSON.parse(storedUserMethodsTablePageSize));
      }
    }
  }, []);

  const ToogleIsAccumulatedMethods = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsAccumulatedMethods',
        JSON.stringify(!isAccumulatedMethods)
      );
      setIsAccumulatedMethods(!isAccumulatedMethods);
    }
  };

  const ToogleIsByOperationMethods = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsByOperationMethods',
        JSON.stringify(!isByOperationMethods)
      );
      setIsByOperationMethods(!isByOperationMethods);
    }
  };

  const handleHiddenColumnsMethodsTableChange = ({
    isVisible,
    id,
  }: HandleHiddenColumnsFromTableChangeData) => {
    if (typeof window !== 'undefined') {
      if (isVisible) {
        const newHiddenColumnsMethodsTable = [
          ...hiddenCollumnsMethodsTable,
          id,
        ];
        setHiddenCollumnsMethodsTable(newHiddenColumnsMethodsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsMethodsTable',
          JSON.stringify(newHiddenColumnsMethodsTable)
        );
      } else {
        const newHiddenColumnsMethodsTable = hiddenCollumnsMethodsTable.filter(
          (column) => {
            return column !== id;
          }
        );
        setHiddenCollumnsMethodsTable(newHiddenColumnsMethodsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsMethodsTable',
          JSON.stringify(newHiddenColumnsMethodsTable)
        );
      }
    }
  };

  const handleHiddenColumnsUserMethodsTableChange = ({
    isVisible,
    id,
  }: HandleHiddenColumnsFromTableChangeData) => {
    if (typeof window !== 'undefined') {
      if (isVisible) {
        const newHiddenColumnsUserMethodsTable = [
          ...hiddenCollumnsUserMethodsTable,
          id,
        ];
        setHiddenCollumnsUserMethodsTable(newHiddenColumnsUserMethodsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsUserMethodsTable',
          JSON.stringify(newHiddenColumnsUserMethodsTable)
        );
      } else {
        const newHiddenColumnsUserMethodsTable =
          hiddenCollumnsUserMethodsTable.filter((column) => {
            return column !== id;
          });
        setHiddenCollumnsUserMethodsTable(newHiddenColumnsUserMethodsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsUserMethodsTable',
          JSON.stringify(newHiddenColumnsUserMethodsTable)
        );
      }
    }
  };

  const handleMethodsTablePageSizeChange = (pageSize: number) => {
    setMethodsTablePageSize(pageSize);
    localStorage.setItem(
      '@Laybackdashboard:MethodsTablePageSize',
      JSON.stringify(pageSize)
    );
  };

  const handleUserMethodsTablePageSizeChange = (pageSize: number) => {
    setUserMethodsTablePageSize(pageSize);
    localStorage.setItem(
      '@Laybackdashboard:UserMethodsTablePageSize',
      JSON.stringify(pageSize)
    );
  };

  return (
    <UserMethodsPreferencesContext.Provider
      value={{
        isAccumulatedMethods,
        ToogleIsAccumulatedMethods,
        isByOperationMethods,
        ToogleIsByOperationMethods,
        hiddenCollumnsMethodsTable,
        handleHiddenColumnsMethodsTableChange,
        methodsTablePageSize,
        handleMethodsTablePageSizeChange,
        hiddenCollumnsUserMethodsTable,
        handleHiddenColumnsUserMethodsTableChange,
        userMethodsTablePageSize,
        handleUserMethodsTablePageSizeChange,
      }}
    >
      {children}
    </UserMethodsPreferencesContext.Provider>
  );
}
