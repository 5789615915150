import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface UserCompetitionsPreferencesData {
  isAccumulatedCompetitions: boolean;
  ToogleIsAccumulatedCompetitions: () => void;
  isByOperationCompetitions: boolean;
  ToogleIsByOperationCompetitions: () => void;
  competitionsSidePreference: string;
  handleCompetitionsSidePreferenceChange: (
    competitionSidePreference: string
  ) => void;
  hiddenCollumnsCompetitionsTable: string[];
  handleHiddenColumnsCompetitionsTableChange: (
    data: HandleHiddenColumnsFromTableChangeData
  ) => void;
  competitionsTablePageSize: number;
  handleCompetitionsTablePageSizeChange: (
    competitionsTablePageSize: number
  ) => void;
}

interface UserCompetitionsPreferencesProviderProps {
  children: ReactNode;
}

interface HandleHiddenColumnsFromTableChangeData {
  isVisible: boolean;
  id: string;
}

export const UserCompetitionsPreferencesContext = createContext(
  {} as UserCompetitionsPreferencesData
);

export function UserCompetitionsPreferencesProvider({
  children,
}: UserCompetitionsPreferencesProviderProps) {
  const [isAccumulatedCompetitions, setIsAccumulatedCompetitions] =
    useState(true);

  const [isByOperationCompetitions, setIsByOperationCompetitions] =
    useState(false);

  const [competitionsSidePreference, setCompetitionsSidePreference] =
    useState('total');

  const [hiddenCollumnsCompetitionsTable, setHiddenCollumnsCompetitionsTable] =
    useState<string[]>([]);

  const [competitionsTablePageSize, setCompetitionsTablePageSize] =
    useState(10);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Gráfico de lucro do dashboard de times, se está acumulado ou não
      const storedIsAccumulatedCompetitions = localStorage.getItem(
        '@Laybackdashboard:IsAccumulatedCompetitions'
      );

      if (storedIsAccumulatedCompetitions) {
        if (storedIsAccumulatedCompetitions === 'false') {
          setIsAccumulatedCompetitions(false);
        } else {
          setIsAccumulatedCompetitions(true);
        }
      }

      // Gráfico de lucro do dashboard de times, se está por operação ou não
      const storedIsByOperationCompetitions = localStorage.getItem(
        '@Laybackdashboard:IsByOperationCompetitions'
      );

      if (storedIsByOperationCompetitions) {
        if (storedIsByOperationCompetitions === 'false') {
          setIsByOperationCompetitions(false);
        } else {
          setIsByOperationCompetitions(true);
        }
      }

      // Preferencia do gráfico de time;
      const storedCompetitionsSidePreference = localStorage.getItem(
        '@Laybackdashboard:CompetitionsSidePreference'
      );

      if (storedCompetitionsSidePreference) {
        setCompetitionsSidePreference(storedCompetitionsSidePreference);
      }

      // Colunas invisiveis da tabela de times;
      const storedHiddenColumnsCompetitionsTable = localStorage.getItem(
        '@Laybackdashboard:HiddenColumnsCompetitionsTable'
      );

      if (storedHiddenColumnsCompetitionsTable) {
        setHiddenCollumnsCompetitionsTable(
          JSON.parse(storedHiddenColumnsCompetitionsTable)
        );
      }

      // Número de linhas da tabela de times
      const storedCompetitionsTablePageSize = localStorage.getItem(
        '@Laybackdashboard:CompetitionsTablePageSize'
      );

      if (storedCompetitionsTablePageSize) {
        setCompetitionsTablePageSize(
          JSON.parse(storedCompetitionsTablePageSize)
        );
      }
    }
  }, []);

  const ToogleIsAccumulatedCompetitions = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsAccumulatedCompetitions',
        JSON.stringify(!isAccumulatedCompetitions)
      );
      setIsAccumulatedCompetitions(!isAccumulatedCompetitions);
    }
  };

  const ToogleIsByOperationCompetitions = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsByOperationCompetitions',
        JSON.stringify(!isByOperationCompetitions)
      );
      setIsByOperationCompetitions(!isByOperationCompetitions);
    }
  };

  const handleHiddenColumnsCompetitionsTableChange = ({
    isVisible,
    id,
  }: HandleHiddenColumnsFromTableChangeData) => {
    if (typeof window !== 'undefined') {
      if (isVisible) {
        const newHiddenColumnsCompetitionsTable = [
          ...hiddenCollumnsCompetitionsTable,
          id,
        ];
        setHiddenCollumnsCompetitionsTable(newHiddenColumnsCompetitionsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsCompetitionsTable',
          JSON.stringify(newHiddenColumnsCompetitionsTable)
        );
      } else {
        const newHiddenColumnsCompetitionsTable =
          hiddenCollumnsCompetitionsTable.filter((column) => {
            return column !== id;
          });
        setHiddenCollumnsCompetitionsTable(newHiddenColumnsCompetitionsTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsCompetitionsTable',
          JSON.stringify(newHiddenColumnsCompetitionsTable)
        );
      }
    }
  };

  const handleCompetitionsTablePageSizeChange = (pageSize: number) => {
    setCompetitionsTablePageSize(pageSize);
    localStorage.setItem(
      '@Laybackdashboard:CompetitionsTablePageSize',
      JSON.stringify(pageSize)
    );
  };

  const handleCompetitionsSidePreferenceChange = useCallback(
    (competitionSidePreference: string) => {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          '@Laybackdashboard:CompetitionsSidePreference',
          competitionSidePreference
        );
        setCompetitionsSidePreference(competitionSidePreference);
      }
    },
    []
  );

  return (
    <UserCompetitionsPreferencesContext.Provider
      value={{
        isAccumulatedCompetitions,
        ToogleIsAccumulatedCompetitions,
        isByOperationCompetitions,
        ToogleIsByOperationCompetitions,
        competitionsSidePreference,
        handleCompetitionsSidePreferenceChange,
        hiddenCollumnsCompetitionsTable,
        handleHiddenColumnsCompetitionsTableChange,
        competitionsTablePageSize,
        handleCompetitionsTablePageSizeChange,
      }}
    >
      {children}
    </UserCompetitionsPreferencesContext.Provider>
  );
}
