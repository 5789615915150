import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface UserCountriesPreferencesData {
  isAccumulatedCountries: boolean;
  ToogleIsAccumulatedCountries: () => void;
  isByOperationCountries: boolean;
  ToogleIsByOperationCountries: () => void;
  countriesSidePreference: string;
  handleCountriesSidePreferenceChange: (countrySidePreference: string) => void;
  hiddenCollumnsCountriesTable: string[];
  handleHiddenColumnsCountriesTableChange: (
    data: HandleHiddenColumnsFromTableChangeData
  ) => void;
  countriesTablePageSize: number;
  handleCountriesTablePageSizeChange: (countriesTablePageSize: number) => void;
}

interface UserCountriesPreferencesProviderProps {
  children: ReactNode;
}

interface HandleHiddenColumnsFromTableChangeData {
  isVisible: boolean;
  id: string;
}

export const UserCountriesPreferencesContext = createContext(
  {} as UserCountriesPreferencesData
);

export function UserCountriesPreferencesProvider({
  children,
}: UserCountriesPreferencesProviderProps) {
  const [isAccumulatedCountries, setIsAccumulatedCountries] = useState(true);

  const [isByOperationCountries, setIsByOperationCountries] = useState(false);

  const [countriesSidePreference, setCountriesSidePreference] =
    useState('total');

  const [hiddenCollumnsCountriesTable, setHiddenCollumnsCountriesTable] =
    useState<string[]>([]);

  const [countriesTablePageSize, setCountriesTablePageSize] = useState(10);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Gráfico de lucro do dashboard de times, se está acumulado ou não
      const storedIsAccumulatedCountries = localStorage.getItem(
        '@Laybackdashboard:IsAccumulatedCountries'
      );

      if (storedIsAccumulatedCountries) {
        if (storedIsAccumulatedCountries === 'false') {
          setIsAccumulatedCountries(false);
        } else {
          setIsAccumulatedCountries(true);
        }
      }

      // Gráfico de lucro do dashboard de times, se está por operação ou não
      const storedIsByOperationCountries = localStorage.getItem(
        '@Laybackdashboard:IsByOperationCountries'
      );

      if (storedIsByOperationCountries) {
        if (storedIsByOperationCountries === 'false') {
          setIsByOperationCountries(false);
        } else {
          setIsByOperationCountries(true);
        }
      }

      // Preferencia do gráfico de time;
      const storedCountriesSidePreference = localStorage.getItem(
        '@Laybackdashboard:CountriesSidePreference'
      );

      if (storedCountriesSidePreference) {
        setCountriesSidePreference(storedCountriesSidePreference);
      }

      // Colunas invisiveis da tabela de times;
      const storedHiddenColumnsCountriesTable = localStorage.getItem(
        '@Laybackdashboard:HiddenColumnsCountriesTable'
      );

      if (storedHiddenColumnsCountriesTable) {
        setHiddenCollumnsCountriesTable(
          JSON.parse(storedHiddenColumnsCountriesTable)
        );
      }

      // Número de linhas da tabela de times
      const storedCountriesTablePageSize = localStorage.getItem(
        '@Laybackdashboard:CountriesTablePageSize'
      );

      if (storedCountriesTablePageSize) {
        setCountriesTablePageSize(JSON.parse(storedCountriesTablePageSize));
      }
    }
  }, []);

  const ToogleIsAccumulatedCountries = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsAccumulatedCountries',
        JSON.stringify(!isAccumulatedCountries)
      );
      setIsAccumulatedCountries(!isAccumulatedCountries);
    }
  };

  const ToogleIsByOperationCountries = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        '@Laybackdashboard:IsByOperationCountries',
        JSON.stringify(!isByOperationCountries)
      );
      setIsByOperationCountries(!isByOperationCountries);
    }
  };

  const handleHiddenColumnsCountriesTableChange = ({
    isVisible,
    id,
  }: HandleHiddenColumnsFromTableChangeData) => {
    if (typeof window !== 'undefined') {
      if (isVisible) {
        const newHiddenColumnsCountriesTable = [
          ...hiddenCollumnsCountriesTable,
          id,
        ];
        setHiddenCollumnsCountriesTable(newHiddenColumnsCountriesTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsCountriesTable',
          JSON.stringify(newHiddenColumnsCountriesTable)
        );
      } else {
        const newHiddenColumnsCountriesTable =
          hiddenCollumnsCountriesTable.filter((column) => {
            return column !== id;
          });
        setHiddenCollumnsCountriesTable(newHiddenColumnsCountriesTable);
        localStorage.setItem(
          '@Laybackdashboard:HiddenColumnsCountriesTable',
          JSON.stringify(newHiddenColumnsCountriesTable)
        );
      }
    }
  };

  const handleCountriesTablePageSizeChange = (pageSize: number) => {
    setCountriesTablePageSize(pageSize);
    localStorage.setItem(
      '@Laybackdashboard:CountriesTablePageSize',
      JSON.stringify(pageSize)
    );
  };

  const handleCountriesSidePreferenceChange = useCallback(
    (countrySidePreference: string) => {
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          '@Laybackdashboard:CountriesSidePreference',
          countrySidePreference
        );
        setCountriesSidePreference(countrySidePreference);
      }
    },
    []
  );

  return (
    <UserCountriesPreferencesContext.Provider
      value={{
        isAccumulatedCountries,
        ToogleIsAccumulatedCountries,
        isByOperationCountries,
        ToogleIsByOperationCountries,
        countriesSidePreference,
        handleCountriesSidePreferenceChange,
        hiddenCollumnsCountriesTable,
        handleHiddenColumnsCountriesTableChange,
        countriesTablePageSize,
        handleCountriesTablePageSizeChange,
      }}
    >
      {children}
    </UserCountriesPreferencesContext.Provider>
  );
}
