import axios from 'axios';

const backendApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

const nextApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NEXT_API_URL,
});

const awsLambdaApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_AWS_LAMBDA_API_URL,
});

const functionsApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_FUNCTIONS_API_URL,
});

export { backendApi, nextApi, awsLambdaApi, functionsApi };
