import { mode } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';
import { PopoverStyles as Popover } from './components/PopoverStyles';
import { ModalStyles as Modal } from './components/ModalStyles';

export const theme = extendTheme({
  colors: {
    primary: {
      '100': '#C8FACD',
      '300': '#5BE584',
      '500': '#00AB55',
      '700': '#007B55',
      '900': '#005249',
    },
    success: {
      '100': '#EBFCD8',
      '300': '#B0EF88',
      '500': '#5DCC39',
      '700': '#28921C',
      '900': '#0A610E',
    },
    danger: {
      '100': '#FFE7D9',
      '300': '#FFA48D',
      '500': '#FF4842',
      '700': '#B72136',
      '900': '#7A0C2E',
    },
    back: {
      '100': '#E3F9FE',
      '200': '#C8F0FE',
      '300': '#ADE4FE',
      '400': '#98D7FD',
      '500': '#76C2FC',
      '600': '#5698D8',
      '700': '#3B72B5',
      '800': '#255092',
      '900': '#163878',
    },
    warning: {
      '100': '#FFF6CE',
      '300': '#FFDD6D',
      '500': '#FFB80C',
      '700': '#B77906',
      '900': '#7A4802',
    },
    lay: {
      '100': '#FEEDEA',
      '200': '#FED9D6',
      '300': '#FCC0C1',
      '400': '#F9AFB7',
      '500': '#F695A9',
      '600': '#D36C8B',
      '700': '#B14B73',
      '800': '#8E2F5E',
      '900': '#761C51',
    },
    gray: {
      '900': '#161C24',
      '800': '#212B36',
      '700': '#454F5B',
      '600': '#637381',
      '500': '#919EAB',
      '400': '#C4CDD5',
      '300': '#DFE3E8',
      '200': '#F4F6F8',
      '100': '#F9FAFB',
    },
  },
  fonts: {
    heading: 'Be Vietnam',
    body: 'Be Vietnam',
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('gray.300', 'gray.900')(props),
        color: mode('gray.900', 'gray.50')(props),
      },
    }),
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  components: {
    Popover,
    Modal,
  },
});
