import { useToast } from '@chakra-ui/react';
import { AxiosInstance } from 'axios';
import { useRouter } from 'next/router';
import { destroyCookie } from 'nookies';
import { createContext } from 'react';
import { ReactNode } from 'react';
import { nextApi } from '../services/api';

interface ApiContextData {
  nextApi: AxiosInstance;
}

interface ApiProviderProps {
  children: ReactNode;
}

export const ApiContext = createContext({} as ApiContextData);

export function ApiProvider({ children }: ApiProviderProps) {
  const toast = useToast();
  const router = useRouter();

  nextApi.interceptors.response.use(undefined, (error) => {
    if (
      error.response.data.message === 'Session expired. Please log in again.'
    ) {
      toast({
        title: 'Sessão expirada',
        description: 'Sua sessão expirou. Por favor faça login novamente.',
        isClosable: true,
        position: 'top-right',
        status: 'error',
      });

      destroyCookie(undefined, 'laybackdashboard.accessToken');

      router.push('/login');

      return;
    } else {
      return error;
    }
  });

  return (
    <ApiContext.Provider
      value={{
        nextApi,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
}
