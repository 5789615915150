export const PopoverStyles = {
  // style object for base or default style
  baseStyle: {},
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    smallest: {
      popper: {
        maxWidth: '180px',
        width: '180px',
        transform: 'translateX(110px)',
      },
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    responsive: {
      popper: {
        maxWidth: 'unset',
        width: 'unset',
      },
      content: {
        maxWidth: 'unset',
      },
    },
    small: {
      popper: {
        width: '260px',
        maxWidth: '260px',
        transform: 'translateX(30px)',
      },
      content: {
        maxWidth: '260px',
      },
    },
    extraSmall: {
      popper: {
        width: '240px',
        maxWidth: '240px',
        transform: 'translateX(50px)',
      },
      content: {
        maxWidth: '240px',
      },
    },
    smallest: {
      popper: {
        width: '180px',
        maxWidth: '180px',
        transform: 'translateX(110px)',
      },
      content: {
        maxWidth: '180px',
      },
    },
  },
  // default values for `size` and `variant`
  defaultProps: {},
};
